import { Dialog } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';

import Buttons from '../04-Micros/Buttons';
import FiltersRow from '../04-Micros/FiltersRow';
import { postBugReport } from '../../Api/bugReport';
import { handleBugReportClose } from '../../Redux/bugReport';
import { useAppDispatch, useAppSelector } from '../../Redux/store';
import {
  PAGE_TITLE,
  formatMatchForBugReport,
  formatSeconds,
  getBugReportFilters,
} from '../../Utils';

const BugReportModal = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(state => state.login.token.access_token);

  const match = useAppSelector(state => state.match.value);
  const modal = useAppSelector(state => state.bugReport);
  const tab = useAppSelector(state => state.navigationTab.value);

  const [activeFilter, setActiveFilter] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const onMessageChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setMessage(e.currentTarget.value);
  };

  const handleClose = () => {
    setActiveFilter('');
    setMessage('');
    dispatch(handleBugReportClose());
  };

  const handleSend = () => {
    if (message) {
      postBugReport({
        token,
        match: formatMatchForBugReport(match),
        issueType: `${activeFilter} - ${PAGE_TITLE[tab]}`,
        description: message,
        eventType: modal.video?.type,
        eventTime: modal.video && formatSeconds(modal.video.time),
      })
        .then(res => {
          toast.success('Chyba úspěšně odeslána.', { toastId: 'bugReportSuccess' });
        })
        .catch(err => {
          console.log('bug report err:', err);
          toast.error('Při odesílání došlo k chybě.', { toastId: 'bugReportError' });
        });
      handleClose();
    } else {
      toast.error('Pro odeslání vyplňte prosím popis chyby.', { toastId: 'bugReportNoMessage' });
    }
  };

  return (
    <Dialog
      className='BugReport'
      open={modal.state}
      onClose={handleClose}
      maxWidth={'md'}
      fullWidth
    >
      <div className='BugReport-header'>
        <h2>Náhlásit chybu</h2>
        <p>{PAGE_TITLE[tab]}</p>
      </div>
      <FiltersRow
        filters={getBugReportFilters(modal.type)}
        active={activeFilter}
        setActive={setActiveFilter}
        paddingHorizontal={18}
        paddingVertical={8}
      />
      <textarea
        className='BugReport-message'
        placeholder='Řekněte nám více o nahlašované chybě.'
        value={message}
        onChange={onMessageChange}
        rows={8}
      />
      <div className='BugReport-buttons'>
        <Buttons
          title='Zavřít'
          type='outlined'
          color='black'
          maxWidth={128}
          onClick={handleClose}
        />
        <Buttons
          title='Odeslat'
          type='outlined'
          color='black'
          maxWidth={128}
          onClick={handleSend}
        />
      </div>
    </Dialog>
  );
};

export default BugReportModal;
